.container{
    position: relative;
    width: 100%;
    min-height: 100dvh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    background: #fafafa;
    overflow-x: hidden;
    /* background: #fff; */
}

.nav{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 20px 5% 20px 5%;
    position: sticky;
    top: 0;
    background: #ffffff;
    z-index: 99;
}

.logo_container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 4px;
    cursor: pointer;
}

.logo{
    width: 85px;
    height: 40px;
    object-fit: contain;
}

.brand{
    color: #5f6368;
    font-family: 'Ubuntu',sans-serif;
    font-size: 24px;
}

.nav_sub_container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 30px;
}

.link{
    font-size: 20px;
    font-family: 'Ubuntu',sans-serif;
    color: #333333;
}

.sign_in_btn{
    padding: 10px 15px 10px 15px;
    border: none;
    outline: none;
    background: #0f58ce;
    color: #fff;
    font-size: 18px;
    font-family: 'Ubuntu',sans-serif;
    border-radius: 20px;
    text-align: center;
    /* box-shadow: 0 10px 20px rgba(0,0,0,0.1); */
}

.main_landing_page{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    place-content: center;
    place-items: stretch;
    padding: 5% 5%;
}

.section_intro{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.brand_intro{
    width: 100%;
    max-width: 350px;
    text-align: left;
    text-wrap: wrap;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}

.brand_main_text{
    font-size: 50px;
    font-family: "Ubuntu",sans-serif;
    font-weight: 800;
    background: #333333;
    background: linear-gradient(to bottom right, #333333 20%, #0F58CE 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.brand_sub_text{
    font-size: 24px;
    font-family: "Ubuntu",sans-serif;
    font-weight: 800;
    color: #333333;
    line-height: 30px;
}

.brand_initiative{
    margin-top: 20px;
    width: 100%;
    text-align: left;
    color: #333;
    font-size: 18px;
    font-family: "Ubuntu",sans-serif;
}

.buttons_wrapper{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.brand_sign_in_btn{
    margin-top: 10px;
    padding: 10px 20px 10px 20px;
    background: #0F58CE;
    color: #fff;
    font-size: 16px;
    font-family: 'Ubuntu',sans-serif;
    border: none;
    outline: none;
    border-radius: 2px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.2);
}

.demo_btn{
    /* background: #0F1014;
    background: #232323; */
    background: #383838;
}

.section_demo{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}

.ai_prompt_box{
    width: 80%;
    min-width: 350px;
    height: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
}

.ai_message_box{
    width: 100%;
    padding: 30px 10px 30px 10px;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.avatar{
    width: 40px;
    height: 40px;
    object-fit: contain;
}

.user_message,.ai_message{
    color: #333333;
    font-size: 20px;
    font-family: 'Ubuntu',sans-serif;
}

.span{
    display: inline-block;
}

.ai_reply_box{
    width: 100%;
    padding: 30px 10px 30px 10px;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}

.section_questions{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    padding: 30px 5% 100px 5%;
}

.section_questions_heading{
    color: #333333;
    font-size: 34px;
    font-family: 'Ubuntu',sans-serif;
    font-weight: 800;
}

.section_drag_questions{
    width: 100%;
    gap: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    user-select: none;
}

.question_grid_1{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(8,1fr);
    gap: 20px;
}

.question_grid_2{
    margin-left: 100px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(8,1fr);
    gap: 20px;
}

.question_grid_3{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(8,1fr);
    gap: 20px;
}

.question_container{
    padding: 30px 10px 30px 10px;
    min-width: 250px;
    max-width: 250px;
    min-height: 80px;
    max-height: 120px;
    background: #F0F4F9;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 4px;
    overflow: hidden;
}

.question{
    color: #333333;
    font-size: 14px;
    font-family: 'Ubuntu',sans-serif;
    text-align: left;
    max-height: 80px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4; 
    -webkit-box-orient: vertical;
    white-space: normal;
    text-overflow: ellipsis;
}

.footer_section{
    position: absolute;
    bottom: 0;
    width: 100%;
}

.footer{
    padding: 5% 5% 16px 5%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}

.copyright{
    color: #333333;
    font-size: 16px;
    font-family: 'Ubuntu',sans-serif;
}
.terms_and_conditions{
    cursor: pointer;
    color: #333333;
    font-size: 16px;
    font-family: 'Ubuntu',sans-serif;
}

@media screen and (max-width:900px){
    .main_landing_page{
        grid-template-columns: 1fr;
        padding: 5% 5%;
    }

    /* .logo_container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 4px;
    } */
}