.container{
    position: relative;
    width: 100%;
    min-height: 100dvh;
    max-height: 100dvh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    background: #fafafa;
    overflow-x: hidden;
    gap: 10px;
    /* background: #fff; */
}

.nav{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 20px 5% 20px 5%;
    position: sticky;
    top: 0;
    background: #ffffff;
    z-index: 99;
}

.logo_container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 4px;
    cursor: pointer;
}

.logo{
    width: 85px;
    height: 50px;
    object-fit: contain;
}

.brand{
    color: #5f6368;
    font-family: 'Ubuntu',sans-serif;
    font-size: 24px;
}

.nav_sub_container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 30px;
}

.sign_in_btn{
    padding: 10px 15px 10px 15px;
    border: none;
    outline: none;
    background: #0f58ce;
    color: #fff;
    font-size: 18px;
    font-family: 'Ubuntu',sans-serif;
    border-radius: 20px;
    text-align: center;
}

/* CHAT CONTAINER... */

.chat_container{
    position: relative;
    width: 100%;
    min-height: calc(100dvh - 10px - 110px) !important;
    max-height: calc(100dvh - 10px - 110px) !important;
    background: #ffffff;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
}

.chat_input_output_container{
    width: 100%;
    min-height: calc(100dvh - 80px - 10px - 20px - 110px);
    max-height: calc(100dvh - 80px - 10px - 20px - 110px);
    padding: 30px 30px 30px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

/* CHAT INPUT BOX STYLING... */

.message_box_container{
    position: sticky;
    bottom: 0;
    width: 100%;
    padding: 0px 30px 10px 20px;
    margin: 0;
    z-index: 99;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    min-height: 100px;
    max-height: 100px;
    /* min-height: 140px;
    max-height: 140px; */
}

.drag_categories{
    padding-left: 4px;
    width: 100%;
    min-height: 50px;
    max-height: 50px;
    width: 100%;
    display: grid;
    align-items: stretch;
    align-content: center;
    grid-template-columns: repeat(5,100px);
    gap: 20px;
    cursor: pointer;
}

.category{
    width: 100px;
    min-height: 30px;
    max-height: 30px;
    border-radius: 2px;
    background: #f0f4f964;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 0 4px #F0F4F9;
}

.category_name{
    color: #333;
    font-size: 16px;
    font-family: 'Ubuntu',sans-serif;
}

.message_wrapper{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
}

.message_box{
    width: 100%;
    border-radius: 40px;
    resize: none; 
    background: #F0F4F9;
    min-height: 60px;
    max-height: 60px;
    border: none;
    outline: none;
    /* padding: 20px 100px 20px 80px; */
    padding: 20px 80px 20px 80px;
    font-size: 18px;
    font-family: 'Ubuntu',sans-serif;
}

.message_box::-webkit-scrollbar {
    width: 0px; 
}
.message_box::-webkit-scrollbar-thumb {
    background-color: #ffffff00;
    border-radius: 0px;
}

.arrow_wrapper{
    position: absolute;
    border: none;
    outline: none;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    z-index: 120;
    width: 40px;
    height:40px;
    /* background: #000; */
    background: #00000022;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:not-allowed;
    transition: all 0.3s;
}

.voice_button{
    position: absolute;
    border: none;
    outline: none;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    z-index: 120;
    width: 40px;
    height:40px;
    /* background: #000; */
    background: #000000;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
    transition: all 0.3s;
}

.voice_button.inactive{
    cursor: not-allowed;
    background: #00000022;
}

.arrow_wrapper.active{
    background: #000;
    cursor: pointer;
}

.arrow_up_icon{
    color: #fff;
    font-size: 20px;
}

@media screen and (max-width:750px){
    .message_box_container{
        padding: 0px 10px 10px 10px;
    }
}



/* INTRO SECTION STYLING.... */

.intro_section{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    /* gap: 10px; */
    padding: calc(5% - 30px) calc(5% - 30px);
}

.intro{
    font-size: 48px;
    font-family: 'Poppins',sans-serif;
    font-weight: 800;
    background: #333333;
    background: linear-gradient(to bottom right, #333333 20%, #0F58CE 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.intro_2{
    font-size: 48px;
    font-family: 'Poppins',sans-serif;
    font-weight: 800;
    color: #5f6368c5;
}

.loader_container{
    width: 100%;
    min-height: 50px;
    display: grid;
    place-content: center;
    place-items: center;
}

@media screen and (max-width:550px){
    .intro{
        font-size: 30px;
    }
    .intro_2{
        font-size: 30px;
    }
}